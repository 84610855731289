import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  constructor(
    public popoverController: PopoverController
  ) { }

  ngOnInit() {}
  async select_filter_option(searchoption) {
    console.log("searchoption: "+searchoption);
    this.dismissPopover(searchoption);
  }

  async dismissPopover(searchoption) {
    await this.popoverController.dismiss({searchoption: searchoption});
  }
}
