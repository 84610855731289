import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'signin', loadChildren: './signin/signin.module#SigninPageModule' },
  { path: 'login', loadChildren: './signin/signin.module#SigninPageModule' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'forgot', loadChildren: './forgot/forgot.module#ForgotPageModule' },
  { path: 'shop', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop/:service_id', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop_search/:search_type/:radius/:q', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop_search/:search_type/:q', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop_search/:q', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop_search', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'barbors', loadChildren: './barbors/barbors.module#BarborsPageModule' },
  { path: 'appointments/:user_id', loadChildren: './appointments/appointments.module#AppointmentsPageModule' },
  { path: 'messages', loadChildren: './messages/messages.module#MessagesPageModule' },
  // { path: 'askquestion', loadChildren: './askquestion/askquestion.module#AskquestionPageModule' }
  { path: 'contact', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'news', loadChildren: './news/news.module#NewsPageModule' },
  { path: 'aboutshop', loadChildren: './aboutshop/aboutshop.module#AboutshopPageModule' },
  { path: 'userprofile', loadChildren: './userprofile/userprofile.module#UserprofilePageModule' },
  { path: 'userprofile/:user_id/:type', loadChildren: './userprofile/userprofile.module#UserprofilePageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'addupload', loadChildren: './addupload/addupload.module#AdduploadPageModule' },
  { path: 'barbercontactinfo', loadChildren: './barbercontactinfo/barbercontactinfo.module#BarbercontactinfoPageModule' },
  { path: 'barberprofile', loadChildren: './barberprofile/barberprofile.module#BarberprofilePageModule' },
  { path: 'barberprofile/:user_id/:type', loadChildren: './barberprofile/barberprofile.module#BarberprofilePageModule' },
  { path: 'invoice', loadChildren: './invoice/invoice.module#InvoicePageModule' },
  { path: 'invoice/:user_id', loadChildren: './invoice/invoice.module#InvoicePageModule' },
  { path: 'chat', loadChildren: './chat/chat.module#ChatPageModule' },
  { path: 'booking', loadChildren: './booking/booking.module#BookingPageModule' },
  { path: 'booking2', loadChildren: './booking2/booking2.module#Booking2PageModule' },
  { path: 'booking2/:booking_id', loadChildren: './booking2/booking2.module#Booking2PageModule' },
  { path: 'services', loadChildren: './services/services.module#ServicesPageModule' },
  { path: 'searchfilter', loadChildren: './searchfilter/searchfilter.module#SearchfilterPageModule' },
  { path: 'managebankcards', loadChildren: './managebankcards/managebankcards.module#ManagebankcardsPageModule' },
  { path: 'managebankcards/:stripe_account_id', loadChildren: './managebankcards/managebankcards.module#ManagebankcardsPageModule' },
  { path: 'choosetype', loadChildren: './choosetype/choosetype.module#ChoosetypePageModule' },
  { path: 'billing', loadChildren: './billing/billing.module#BillingPageModule' },
  { path: 'billing/:type', loadChildren: './billing/billing.module#BillingPageModule' },
  { path: 'billing/:type/:page', loadChildren: './billing/billing.module#BillingPageModule' },
  { path: 'thankyou', loadChildren: './thankyou/thankyou.module#ThankyouPageModule' },
  { path: 'gallery-custom-modal', loadChildren: './gallery-custom-modal/gallery-custom-modal.module#GalleryCustomModalPageModule' },
  { path: 'cardinfo', loadChildren: './cardinfo/cardinfo.module#CardinfoPageModule' },
  { path: 'billingedit/:type/:card_id/:card_last4/:card_brand/:card_exp_month/:card_exp_year/:card_name/edit/:default', loadChildren: './billingedit/billingedit.module#BillingeditPageModule' },
  { path: 'singlefeed/:id', loadChildren: './singlefeed/singlefeed.module#SinglefeedPageModule' },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule' },
  { path: 'myservicesadd', loadChildren: './myservicesadd/myservicesadd.module#MyservicesaddPageModule' },
  { path: 'my-barbers', loadChildren: './my-barbers/my-barbers.module#MYBarbersPageModule' },
  { path: 'favorite-barbers', loadChildren: './favorite-barbers/favorite-barbers.module#FavoriteBarbersPageModule' },
  { path: 'capture-photo', loadChildren: './capture-photo/capture-photo.module#CapturePhotoPageModule' },
  { path: 'wallet', loadChildren: './wallet/wallet.module#WalletPageModule' },
  { path: 'walletrequest', loadChildren: './walletrequest/walletrequest.module#WalletrequestPageModule' },
  { path: 'working-hours', loadChildren: './working-hours/working-hours.module#WorkingHoursPageModule' },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
