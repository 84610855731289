import { Component } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationExtras, Router } from '@angular/router';
import { Events, MenuController, NavController, AlertController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { OneSignal, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import * as firebase from 'firebase';
import { AllServicesService } from './all-services.service';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent {
    appPages: Array<{ title: string, url: any, icon: any }>;
    logout: any;
    userDetails: any;
    is_offer: boolean = false;
    data_notification: any = [];
    user_info: any = [];
    loading: any;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private menu: MenuController,
        public navCtrl: NavController,
        private oneSignal: OneSignal,
        public alertController: AlertController,
        public storage: Storage,
        public events: Events,
        public toastController: ToastController,
        public allServicesService: AllServicesService,
        public loadingCtrl: LoadingController
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            //this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#ca0d13');
            // this.oneSignal.startInit('f04cb0f3-56ad-4001-99d2-3edc22e08bcb');
            this.oneSignal.startInit('e96af6bf-e414-463e-b709-da7a88e0c75e', '687029074590');
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
            this.oneSignal.handleNotificationReceived().subscribe(data => this.onPushReceived(data.payload));
            // this.oneSignal.handleNotificationOpened().subscribe(data => this.onPushOpened(data.notification.payload));
            this.oneSignal.handleNotificationOpened().subscribe((data) => {
                this.storage.get('user').then(val => {
                    console.log('handleNotificationReceived2', data);
                    if (val != null) {
                        this.data_notification = data;
                        let noti_type: any = '';
                        let postid: number;
                        let reciver_id: any;
                        let sender_id: any;

                        noti_type = this.data_notification.notification.payload.additionalData.noti_type;
                        postid = this.data_notification.notification.payload.additionalData.post_id;
                        reciver_id = this.data_notification.notification.payload.additionalData.reciver_id;
                        sender_id = this.data_notification.notification.payload.additionalData.sender_id;

                        if (noti_type == 'user_chat') {
                            this.router.navigate(['/tabs/messages']);
                            // this.getOtherUserDetails(sender_id);
                            // this.showLoader();
                            // this.allServicesService.getCurrentUserInfo(val.token).subscribe((result) => {
                            //   let res: any = [];
                            //   res = result;
                            //   this.loading.dismiss();
                            //   this.openChatPage(res.result);
                            // }, (err) => {
                            //   this.loading.dismiss();
                            //   console.log("error...", err);
                            //   let msg = err.error.errormsg;
                            //   this.presentToast(msg);
                            // });
                        }

                        if (noti_type == "barber_request") {
                            this.router.navigate(['/my-barbers']);
                        }
                        if (noti_type == "appointment_request" || noti_type == "appointment_cancel" || noti_type == "appointment_start") {
                            this.router.navigate(['/booking2/' + postid]);
                        }
                    } else {
                        this.presentToast('Please login to check notification details');
                    }

                });
            });
            this.oneSignal.endInit();

            var firebaseConfig = {
                apiKey: "AIzaSyC8bE8U2uWn_d9KwaTJdS5emQHxjmsxZYE",
                authDomain: "barber-assistant.firebaseapp.com",
                databaseURL: "https://barber-assistant.firebaseio.com",
                projectId: "barber-assistant",
                storageBucket: "barber-assistant.appspot.com",
                messagingSenderId: "687029074590",
                appId: "1:687029074590:web:e40ab131c59c3f2081198f"
            };
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);


            setTimeout(() => {
                this.splashScreen.hide();
            }, 1000);

            this.storage.get('user').then(userInfo => {

                if (userInfo != null) {
                    this.events.publish('userCheck:created', userInfo);
                    if (!this.is_offer) {
                        this.router.navigate(['/tabs/shop']);
                    }
                    //services
                } else {
                    this.events.publish('userCheck:created', 'userNotLogin');
                    if (!this.is_offer) {
                        this.router.navigate(['/home']);
                    }
                    // this.router.navigate(['/signin']);
                }

            });
        });

        this.events.subscribe('userCheck:created', (user) => {
            this.logout = true;
            if (user.token) {
                if (user.role == "barber") {
                    this.appPages = [
                        {
                            title: 'Home',
                            url: '/tabs/shop',
                            icon: 'home'
                        },
                        {
                            title: 'My Profile',
                            url: '/barberprofile/' + user.user_id + '/private',
                            icon: 'person'
                        },
                        {
                            title: 'Stripe Setup',
                            url: '/managebankcards',
                            icon: 'card'
                        },
                        {
                            title: 'Payouts',
                            url: '/wallet',
                            icon: 'card'
                        },
                        {
                            title: 'My Barbers',
                            url: '/my-barbers',
                            icon: 'person'
                        },
                        {
                            title: 'Notifications',
                            url: '/notifications',
                            icon: 'notifications'
                        },
                        {
                            title: 'Contact',
                            url: '/contact',
                            icon: 'mail'
                        },
                        {
                            title: 'About',
                            url: '/about',
                            icon: 'person'
                        },
                        {
                            title: 'News',
                            url: '/news',
                            icon: 'paper'
                        },
                    ]
                }
                else if (user.role == "barber_staff" && (user.shop_user_id == '' || user.shop_user_id == null)) {
                    this.appPages = [
                        {
                            title: 'Home',
                            url: '/tabs/shop',
                            icon: 'home'
                        },
                        {
                            title: 'My Profile',
                            url: '/barberprofile/' + user.user_id + '/private',
                            icon: 'person'
                        },
                        {
                            title: 'Stripe Setup',
                            url: '/managebankcards',
                            icon: 'card'
                        },
                        {
                            title: 'Payouts',
                            url: '/wallet',
                            icon: 'card'
                        },
                        {
                            title: 'Notifications',
                            url: '/notifications',
                            icon: 'notifications'
                        },
                        {
                            title: 'Contact',
                            url: '/contact',
                            icon: 'mail'
                        },
                        {
                            title: 'About',
                            url: '/about',
                            icon: 'person'
                        },
                        {
                            title: 'News',
                            url: '/news',
                            icon: 'paper'
                        },
                    ]
                }
                else if (user.role == "barber_staff") {
                    this.appPages = [
                        {
                            title: 'Home',
                            url: '/tabs/shop',
                            icon: 'home'
                        },
                        {
                            title: 'My Profile',
                            url: '/barberprofile/' + user.user_id + '/private',
                            icon: 'person'
                        },
                        // {
                        //   title: 'My Cards',
                        //   url: '/managebankcards',
                        //   icon: 'card'
                        // },
                        {
                            title: 'Notifications',
                            url: '/notifications',
                            icon: 'notifications'
                        },
                        {
                            title: 'Contact',
                            url: '/contact',
                            icon: 'mail'
                        },
                        {
                            title: 'About',
                            url: '/about',
                            icon: 'person'
                        },
                        {
                            title: 'News',
                            url: '/news',
                            icon: 'paper'
                        },
                    ]
                } else {
                    this.appPages = [
                        {
                            title: 'Home',
                            url: '/tabs/shop',
                            icon: 'home'
                        },

                        {
                            title: 'My Profile',
                            url: '/userprofile/' + user.user_id + '/private',
                            icon: 'person'
                        },
                        {
                            title: 'My Cards',
                            url: '/managebankcards',
                            icon: 'card'
                        },
                        {
                            title: 'My Favorite Barbers',
                            url: '/favorite-barbers',
                            icon: 'person'
                        },
                        {
                            title: 'Notifications',
                            url: '/notifications',
                            icon: 'notifications'
                        },
                        {
                            title: 'Contact',
                            url: '/contact',
                            icon: 'mail'
                        },
                        {
                            title: 'About',
                            url: '/about',
                            icon: 'person'
                        },
                        {
                            title: 'News',
                            url: '/news',
                            icon: 'paper'
                        },
                    ]
                }

            }
            if (user == 'userNotLogin') {

                this.logout = false;
                this.appPages = [


                    {
                        title: 'Home',
                        url: '/tabs/shop',
                        icon: 'home'
                    },
                    // {
                    //   title: 'Contact',
                    //   url: '/contact',
                    //   icon: 'mail'
                    // },
                    {
                        title: 'About',
                        url: '/about',
                        icon: 'person'
                    },
                    {
                        title: 'News',
                        url: '/news',
                        icon: 'paper'
                    },
                    {
                        title: 'Login / Signup',
                        url: '/home',
                        icon: 'person'
                    }
                ]
            }
        });

    }

    private onPushReceived(payload: OSNotificationPayload) {
        console.log('Push recevied:' + payload.additionalData);
    }

    openForm() {
        this.navCtrl.navigateForward(['/forms', { form_id: 1 }]);
    }

    // private onPushOpened(payload: OSNotificationPayload) {
    //   this.is_offer = true;
    //   this.storage.get('user').then(userInfo => {
    //     if (userInfo != null) {
    //       let event_notification = payload.additionalData;
    //       if (event_noti_type == "appointment_complete" || event_noti_type == "appointment_start" || event_noti_type == "appointment_request") {
    //         this.router.navigate(['/booking2/' + event_notification.booking_id]);
    //       } else {
    //         this.router.navigate(['/tabs/shop']);
    //       }
    //     } else {
    //       this.router.navigate(['/home']);
    //     }

    //   }, error => {
    //     this.router.navigate(['/home']);
    //   });
    // }

    async DoLogout() {
        this.menu.toggle();
        const alert = await this.alertController.create({
            header: 'Logout',
            message: 'Are you sure ?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                    }
                }, {
                    text: 'Logout',
                    handler: () => {
                        this.storage.clear();
                        this.events.publish('userCheck:created', 'userNotLogin');
                        this.router.navigate(['/home']);
                    }
                }
            ]
        });
        await alert.present();

    }

    async presentToast(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000,
            position: 'bottom'
        });
        toast.present();
    }

    async showLoader() {
        this.loading = await this.loadingCtrl.create({ message: 'Please wait...' });
        this.loading.present();
    }

    getOtherUserDetails(uid) {

        this.storage.get('user').then((user) => {
            if (user) {
                this.allServicesService.get_other_user_details(uid, user.token).subscribe((res) => {
                    console.log('user_info ====', res);
                    let result: any = []
                    result = res;
                    this.user_info = result.user_data;
                }, (err) => {
                    console.log("error...", err);
                    let msg = err.error.errormsg;
                    this.presentToast(msg);
                });
            } else {
                let msg = "Invalid token, Please try again.";
                this.presentToast(msg);
            }
        });
    }

    openChatPage(Currentuser) {
        let userPro = {
            id: this.user_info.user_id,
            title: this.user_info.nicename,
            user_img: this.user_info.user_avatar,
        };
        let navigationExtras: NavigationExtras = {
            queryParams: {
                //  special: JSON.stringify(workout),
                secondUser: JSON.stringify(userPro),
                currentUser: JSON.stringify(Currentuser),
                fromMy: true
            }
        };
        this.navCtrl.navigateForward(['/chat'], navigationExtras);
    }
}